<template>
  <div>
    <el-row style="margin-left: 10%;margin-top: 1%;size: B4">
      <el-col :span="11">
        <el-form label-width="100px" style="margin-top: 10px;margin-left: 10px" :model="form" :rules="rules" ref="form">

          <el-row>
            <el-col :span="22">
              <el-form-item label="内容" prop="content" label-width="110px">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="form.content">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="支出发生日期" prop="createDate" label-width="110px">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.createDate"
                    type="date"
                    placeholder="选择支出发生日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="支出项目" prop="spendProjectsId" label-width="110px">
                <el-select v-model="form.spendProjectsId" placeholder="请选择支出项目" clearable filterable>
                  <el-option
                      v-for="item in projectsArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="projectOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="店铺" prop="shopId" label-width="110px">
                <el-select v-model="form.shopId" placeholder="请选择店铺" clearable filterable>
                  <el-option
                      v-for="item in shopArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="shopOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="支出金额" prop="spendAmount" label-width="110px">
                <el-input v-model="form.spendAmount"
                          placeholder="请填写支出金额"
                          clearable
                          style="width: 217px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="报销人" prop="spendPeopleId" label-width="110px">
                <el-select v-model="form.spendPeopleId" placeholder="请选择报销人" clearable filterable>
                  <el-option
                      v-for="item in spendPeopleArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="spendPeopleOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>
            <el-col :span="22">
              <el-form-item label="支出图片" label-width="110px">
                <el-row>
                  <el-col>
                    <div>
                      <el-row>
                        <UploadImages @files="getFiles" :img-size="0"/>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="公司结算方式" prop="spendMethod" label-width="110px">
                <el-select v-model="form.spendMethod" placeholder="请选择公司结算方式" clearable filterable>
                  <el-option
                      v-for="item in accountArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="spendMethodOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="结算时间" prop="spendMethodTime" label-width="110px">
                <el-date-picker
                    v-model="form.spendMethodTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择结算时间"
                    align="right"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item style="margin-left: 10px;">
                <el-button type="primary" @click="spendDirect('form')">立即提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UploadImages from "@/components/image/uploadImages.vue";

export default {
  name: "spend-direct",
  components: {UploadImages},
  created() {
    this.pageInit()
  },
  data() {
    return {
      loading: false,
      fileList: [],
      spendPictureList: [],

      projectsArray: [],
      accountArray: [],
      shopArray: [],
      spendPeopleArray: [],
      dialogImageUrl: "",
      spendProjectsName: '',

      createPeopleId: "",
      createPeopleName: "",

      form: {
        spendMethod: "",
        spendAmount: "",
        spendProjectsId: "",
        shopId: "",
        spendPeopleId: "",
        content: "",
        spendImage: "",
        createDate: this.$dateUtils.getTimeStr('d'),
        spendMethodTime: this.$dateUtils.getTimeStr('s'),
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      rules: {
        spendMethod: [{required: true, message: '请选择结算方式', trigger: 'blur'}],
        spendAmount: [{required: true, message: '请输入支出金额', trigger: 'blur'}],
        spendProjectsId: [{required: true, message: '请选择支出项目', trigger: 'blur'}],
        shopId: [{required: true, message: '请选择店铺', trigger: 'blur'}],
        spendPeopleId: [{required: true, message: '请选择报销人', trigger: 'blur'}],
        createDate: [{required: true, message: '请选择支出时间', trigger: 'blur'}],
        content: [{required: true, message: '请输入申报内容', trigger: 'blur'}],
        spendMethodTime: [{required: true, message: '请选择结算时间', trigger: 'blur'}],
      },

      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {

    //直接添加支出
    spendDirect(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.spendAmount < 0) {
            this.$alert('支出金额不能是负数!', '', {
              confirmButtonText: '确定',
            });
            return false
          }

          if (this.fileList.length > 9) {
            this.$alert('一条支出最多传9张支出图片!', '', {
              confirmButtonText: '确定',
            });
            return false
          }

          this.$alert("支出金额： " + this.form.spendAmount
              + '<br><br>确认信息无误？',
              "直接加支出",
              {
                dangerouslyUseHTMLString: true,
                type: "success",
                center: true,
                showCancelButton: true
              }).then(() => {
            //没图片，只加支出的。
            if (this.fileList.length == 0) {
              this.addSpend().then(response => {
                this.$message({
                  showClose: true,
                  message: response.data.code === 200 ? "支出添加成功" : response.data.msg,
                  type: response.data.code === 200 ? 'success' : 'error',
                  duration: 1000,
                })

                if (response.data.code === 200) {
                  setTimeout(location.reload(), 1000);
                  return;
                }
              })
            }

            //有加支出图片，则先加支出数据，再加支出图片。
            if (this.fileList.length >= 1) {
              this.addSpend().then(response => {
                if (response.data.code !== 200) {
                  this.$message.error(response.data.msg);
                  return;
                }

                //this.addSpend() 支出数据添加成功后，再执行下面这个。
                if (response.data.code === 200) {
                  if ([null, '', undefined].includes(response.data.data)) {
                    this.$alert("添加支出失败，请联系管理员查看。",
                        {
                          dangerouslyUseHTMLString: true,
                          type: "error",
                          center: true,
                        }).then(() => {

                      return;
                      // on close
                    });
                  }

                  //获取添加支出后返回的id
                  this.spendId = response.data.data;
                  // console.log("this.spendId")
                  // console.log(this.spendId)

                  this.loading = true
                  this.uploadSpendImage().then(value => {
                    if (!value) {
                      this.$message({
                        showClose: true,
                        message: "图片上传发生错误,请检查后重新上传",
                        type: 'error',
                        duration: 1500,
                      })
                    } else {
                      this.$axios({
                        method: "PUT",
                        url: "/spend/saveBatchSpendImageAll",
                        data: {
                          tenantCrop: localStorage.getItem("tenantCrop"),
                          spendPictureList: this.spendPictureList,
                        }
                      }).then(res => {
                        console.log("res")
                        console.log(res)
                        this.$message({
                          showClose: true,
                          message: res.data.code === 200 ? "支出添加成功" : res.data.msg,
                          type: res.data.code === 200 ? 'success' : 'error',
                          duration: 1000,
                        })
                        this.loading = false

                        if (res.data.code === 200) {
                          setTimeout(location.reload(), 5000);
                        }
                      })
                    }
                  })


                }


              })
            }

          }).catch(() => {
          })

        } else {
          return false;
        }
      });
    },

    //单纯加条支出数据，返回 response。response.data.data里有返回的 spendId。
    addSpend() {
      return this.$axios({
        method: "POST",
        url: "/spend/addSpend",
        data: {
          'tenantCrop': this.form.tenantCrop,
          'content': this.form.content,
          'createDate': this.form.createDate,
          'spendProjectsId': this.form.spendProjectsId,
          'shopId': this.form.shopId,
          'spendAmount': this.form.spendAmount,
          'spendPeopleId': this.form.spendPeopleId,
          'spendMethodId': this.form.spendMethod,
          'spendMethodTime': this.form.spendMethodTime,
          //'settlement': 1, //直接加支出。 已结算 (1）
          'stateId': 4, // 直接加支出，设置审核状态为 已结算(4)

          // ...this.form
        },
      })
    },

    uploadSpendImage: function () {
      return new Promise((resolve, reject) => {
        if (this.fileList < 1) {
          return resolve(true)
        }
        //构建腾讯需要的文件对象
        let buildFlies = this.buildFiles();
        this.$upload.getTemporaryKey(this.$upload.expendImage).uploadFiles({
          files: buildFlies,
          SliceSize: 1024 * 1024 * 50, //图片大于50M则分块上传
        }, function (err, data) {
          let error = false;
          data.files.forEach(k => {
            //如果不等于null 则说明图片上传有误 则返回true
            error = k.error !== null ? true : error;
          })
          if (err !== null || error) {
            this.$message.error("上传失败,请刷新重试!")
            resolve(false)
          } else {
            resolve(true)
          }
        })

      })
    },

    //从子组件获取file
    getFiles(file) {
      this.fileList = file;
      // console.log("fileList")
      // console.log(this.fileList)
    },

    //构建多文件上传时的格式
    buildFiles() {
      let buildFiles = [];
      this.fileList.forEach(k => {
        let arr = k.name.split(".");
        let fileName = this.$md5(arr[0] + new Date()) + "." + arr[arr.length - 1];
        // this.formA.imageNames.push(fileName);
        this.spendPictureList.push({
          'spendId': this.spendId,
          'spendImage': fileName,
          // // 'imageType': "",
          'createPeopleName': this.createPeopleName,
          'createPeopleId': this.createPeopleId,
          //创建时间和更新时间 好像不用传过来，null的话数据库好像会默认当前。
          // 'createDate': this.$dateUtils.getTimeStr('s'),
          // 'updateDate': this.$dateUtils.getTimeStr('s'),
        });
        buildFiles.push({
          Bucket: this.$upload.getBucketName(this.$upload.expendImage), /* 填入您自己的存储桶，必须字段 */
          Region: this.$upload.getRegion(),  /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: fileName,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: k.raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
        })
      })
      return buildFiles;
    },

    //重置
    reset(form) {
      this.$refs[form].resetFields();
      this.dialogImageUrl = ''
      this.$refs['upload'].clearFiles()
    },

    pageInit() {
      /* 查支出项目参数 */
      this.$selectUtils.queryProjectsByType(this.$projectsType.category).then(response => {
        this.projectsArray = JSON.parse(response.data.data)
      })
      /* 查店铺参数 */
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopArray = JSON.parse(response.data.data)
      })
      /* 查报销人参数 */
      this.$selectUtils.queryEmpIds().then(response => {
        this.spendPeopleArray = JSON.parse(response.data.data);

        console.log("JSON.parse(localStorage.getItem('emp')).empName")
        console.log(JSON.parse(localStorage.getItem('emp')))

        //老板要求报销人是 登录人。
        this.spendPeopleArray.find(s => {
          if (s.value == JSON.parse(localStorage.getItem('emp')).id) {
            this.form.spendPeopleId = s.value
            // this.spendPeopleText = s.text

            this.createPeopleId = s.value
            this.createPeopleName = s.name
          }
        })
        // spendPeople: JSON.parse(localStorage.getItem('emp')).empName,
      })
      // /* 查公司结算方式参数 */
      // this.querySpendPeopleList();
      // this.$selectUtils.queryStyleImageTypes(0).then(response => {
      //   this.styleImageArray = JSON.parse(response.data.data)
      // })
      /* 查公司结算方式参数 */
      this.$selectUtils.queryAccountIds().then(response => {
        this.accountArray = JSON.parse(response.data.data)
      })
    },

    //支出项目确认。
    projectOnConfirm: function (value) {
      this.form.spendProjectsId = value.value;
      // this.projectText = value.text;
    },

    //店铺确认。
    shopOnConfirm: function (value) {
      this.form.shopId = value.value;
      // this.shopText = value.text;
    },

    //报销人确认。
    spendPeopleOnConfirm: function (value) {
      this.form.spendPeopleId = value.value;
      console.log("this.spendPeopleId")
      console.log(this.spendPeopleId)
      // this.spendPeopleText = value.text;
    },

    //公司结算方式确认。
    spendMethodOnConfirm: function (value) {
      this.form.spendMethod = value.value;
      // this.spendMethodText = value.name;
    },

    //级联选择关闭
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false;
    },

    // querySpendPeopleList() {
    //   this.$axios({
    //     method: "GET",
    //     url: "/spendPeople/querySpendPeopleList",
    //     params: {
    //       spendPeople: JSON.parse(localStorage.getItem("emp")).id,
    //       tenantCrop: localStorage.getItem("tenantCrop"),
    //     }
    //   }).then(response => {
    //     this.SpendPeopleArray = response.data.data.list;
    //   })
    // },
  },


}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
  border: #8c939d;
}
</style>